import clsx from "clsx";
import { useRecoilValue } from "recoil";

import { useEffect, useState } from "react";
import { ApiService } from "../../../generated";
import { PageSection } from "../../../library";
import { userState } from "../../../recoil/user";
import ClarityCallout from "../../../shared/ClarityCallout";
import { borderColorClass } from "../../../utils/colors";
import { handleError } from "../../../utils/generatedApi";
import { trackSupplierWelcomePageView } from "../../../utils/tracking";
import BuyerInsights from "../SupplierWelcomePage/BuyerInsights";
import ExpiredAgreementsAccordion from "../SupplierWelcomePage/ExpiredAgreementsAccordion";
import SupplierFAQ from "../SupplierWelcomePage/SupplierFAQ";
import SupplierWelcomeBanner from "../SupplierWelcomePage/SupplierWelcomeBanner";
import { INITIAL_VIEW_ANALYTICS } from "../constants";
import type { SupplierWelcomePageData, ViewAnalytics } from "../types";
import ProSupplierPageHeader from "./ProSupplierPageHeader";
import ProSupplierWelcomeTasks from "./ProSupplierWelcomeTasks";
import QEVTracker from "./QEVTracker";
import { getCallouts } from "./helpers";

interface ProSupplierWelcomePageProps {
  numActiveShareableContracts: number;
  supplierLogoUrl: string;
}

export default function ProSupplierWelcomePage({
  numActiveShareableContracts,
  supplierLogoUrl,
}: ProSupplierWelcomePageProps) {
  const { email, supplier } = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);
  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalytics>(
    INITIAL_VIEW_ANALYTICS
  );

  const supplierData: SupplierWelcomePageData = {
    supplierId: supplier?.id,
    supplierHandle: supplier?.handle,
    supplierName: supplier?.displayName,
    isProSupplier: true,
    userEmail: email,
    numActiveShareableContracts,
    numLeads: viewAnalytics.leads.length,
    numBuyerSessions: viewAnalytics.sessions?.length || 0,
    numEntitySessions: viewAnalytics.entityActivity?.length || 0,
    totalViews: viewAnalytics.totalViews,
  };

  useEffect(() => {
    (async () => {
      if (!supplier?.handle) return;

      setLoading(true);
      try {
        const response = await ApiService.apiV1SuppliersViewAnalyticsRetrieve(
          supplier.handle
        );
        setViewAnalytics(response);
      } catch (err) {
        handleError(err);
      }

      setLoading(false);
    })();
  }, [supplier]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once on mount.
  useEffect(() => {
    trackSupplierWelcomePageView(supplierData);
  }, []);

  return (
    <div className="analytics-pro-supplier-welcome-page flex flex-col">
      <SupplierWelcomeBanner isProSupplier />
      <PageSection>
        <div className="flex flex-col gap-8 py-10 md:mb-10">
          <ProSupplierWelcomeTasks className="hidden sm:flex" />
          <div
            className={clsx(
              "flex flex-col gap-6 p-6 border border-solid rounded-8",
              borderColorClass.neutral.subtle.enabled
            )}
          >
            <ProSupplierPageHeader
              dateViewsStart={viewAnalytics.dateViewsStart}
              supplierName={supplier?.displayName}
              logoUrl={supplierLogoUrl}
            />
            <QEVTracker viewAnalytics={viewAnalytics} isLoading={false} />
            <ClarityCallout
              callouts={getCallouts({
                viewAnalytics,
                supplierName: supplier?.displayName,
              })}
            />
          </div>
          <BuyerInsights
            viewAnalytics={viewAnalytics}
            supplierData={supplierData}
            isLoading={loading}
          />
          <ExpiredAgreementsAccordion />
        </div>
      </PageSection>
      <SupplierFAQ />
    </div>
  );
}
