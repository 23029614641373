/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAddFile } from '../models/AdminAddFile';
import type { AdminAddFileRequest } from '../models/AdminAddFileRequest';
import type { AdminBuyerLeadAgency } from '../models/AdminBuyerLeadAgency';
import type { AdminContract } from '../models/AdminContract';
import type { AdminContractFragment } from '../models/AdminContractFragment';
import type { AdminContractFragmentRequest } from '../models/AdminContractFragmentRequest';
import type { AdminFullSupplier } from '../models/AdminFullSupplier';
import type { AdminOffering } from '../models/AdminOffering';
import type { AdminOfferingMerge } from '../models/AdminOfferingMerge';
import type { AdminOfferingMergeRequest } from '../models/AdminOfferingMergeRequest';
import type { AdminOfferingRequest } from '../models/AdminOfferingRequest';
import type { AdminOfferingSynonym } from '../models/AdminOfferingSynonym';
import type { AdminOfferingSynonymRequest } from '../models/AdminOfferingSynonymRequest';
import type { AdminRemoveFile } from '../models/AdminRemoveFile';
import type { AdminRemoveFileRequest } from '../models/AdminRemoveFileRequest';
import type { AdminSearchQueryResolution } from '../models/AdminSearchQueryResolution';
import type { AdminSearchQueryResolutionRequest } from '../models/AdminSearchQueryResolutionRequest';
import type { AdminSupplierConnection } from '../models/AdminSupplierConnection';
import type { AdminSupplierConnectionRequest } from '../models/AdminSupplierConnectionRequest';
import type { AdminSupplierConnectionRequestRequest } from '../models/AdminSupplierConnectionRequestRequest';
import type { AdminSupplierContact } from '../models/AdminSupplierContact';
import type { AdminSupplierContactFragment } from '../models/AdminSupplierContactFragment';
import type { AdminSupplierContactFragmentRequest } from '../models/AdminSupplierContactFragmentRequest';
import type { AdminSupplierFragment } from '../models/AdminSupplierFragment';
import type { AdminSupplierFragmentRequest } from '../models/AdminSupplierFragmentRequest';
import type { AdminUpdateFile } from '../models/AdminUpdateFile';
import type { AdminUpdateFileRequest } from '../models/AdminUpdateFileRequest';
import type { ApprovedSource } from '../models/ApprovedSource';
import type { ApprovedSourceRequest } from '../models/ApprovedSourceRequest';
import type { ArchiveProjectRequest } from '../models/ArchiveProjectRequest';
import type { BenchmarkFromQueries } from '../models/BenchmarkFromQueries';
import type { BenchmarkSearchQueriesRequest } from '../models/BenchmarkSearchQueriesRequest';
import type { BuyerIntentSurvey } from '../models/BuyerIntentSurvey';
import type { BuyerIntentSurveyRequest } from '../models/BuyerIntentSurveyRequest';
import type { BuyerLeadAgency } from '../models/BuyerLeadAgency';
import type { BuyerLeadAgencyInvites } from '../models/BuyerLeadAgencyInvites';
import type { BuyerLeadAgencyMember } from '../models/BuyerLeadAgencyMember';
import type { BuyerLeadAgencyRequest } from '../models/BuyerLeadAgencyRequest';
import type { BuyerLeadAgencySetting } from '../models/BuyerLeadAgencySetting';
import type { BuyerProfile } from '../models/BuyerProfile';
import type { BuyerProfileRequest } from '../models/BuyerProfileRequest';
import type { ChangeRoleRequest } from '../models/ChangeRoleRequest';
import type { ContactBuyerLeadAgencyRequest } from '../models/ContactBuyerLeadAgencyRequest';
import type { ContactContractFeedbackRequest } from '../models/ContactContractFeedbackRequest';
import type { ContactEmailSupplierRequest } from '../models/ContactEmailSupplierRequest';
import type { ContactMissingDocumentsRequest } from '../models/ContactMissingDocumentsRequest';
import type { ContractSearchRequest } from '../models/ContractSearchRequest';
import type { ContractSearchResponse } from '../models/ContractSearchResponse';
import type { ContractValidation } from '../models/ContractValidation';
import type { ContractValidationRequest } from '../models/ContractValidationRequest';
import type { CreateApprovedSourceRequest } from '../models/CreateApprovedSourceRequest';
import type { CreateBLARequest } from '../models/CreateBLARequest';
import type { CreateBuyerQualificationSession } from '../models/CreateBuyerQualificationSession';
import type { CreateBuyerQualificationSessionRequest } from '../models/CreateBuyerQualificationSessionRequest';
import type { DuplicateSupplierCandidate } from '../models/DuplicateSupplierCandidate';
import type { EntityContractsRequest } from '../models/EntityContractsRequest';
import type { ExternalAPIIngestedContractRequest } from '../models/ExternalAPIIngestedContractRequest';
import type { IntermediatedEmailAlias } from '../models/IntermediatedEmailAlias';
import type { InviteTeamRequest } from '../models/InviteTeamRequest';
import type { InviteToProjectRequest } from '../models/InviteToProjectRequest';
import type { LandingPageSearchRequest } from '../models/LandingPageSearchRequest';
import type { MegaCategories } from '../models/MegaCategories';
import type { OfferingSearchResponse } from '../models/OfferingSearchResponse';
import type { OtherContractsResponse } from '../models/OtherContractsResponse';
import type { PaginatedAdminSupplierConnectionRequestList } from '../models/PaginatedAdminSupplierConnectionRequestList';
import type { PatchedAdminOfferingSynonymRequest } from '../models/PatchedAdminOfferingSynonymRequest';
import type { PatchedAdminSearchQueryResolutionRequest } from '../models/PatchedAdminSearchQueryResolutionRequest';
import type { PatchedApprovedSourceRequest } from '../models/PatchedApprovedSourceRequest';
import type { PatchedBuyerIntentSurveyRequest } from '../models/PatchedBuyerIntentSurveyRequest';
import type { PatchedBuyerLeadAgencySettingRequest } from '../models/PatchedBuyerLeadAgencySettingRequest';
import type { PatchedBuyerProfileRequest } from '../models/PatchedBuyerProfileRequest';
import type { PatchedBuyerSurveyPatchResponseRequest } from '../models/PatchedBuyerSurveyPatchResponseRequest';
import type { PatchedProjectRequest } from '../models/PatchedProjectRequest';
import type { PatchedQualifiedUserInteractionRequest } from '../models/PatchedQualifiedUserInteractionRequest';
import type { PatchedSupplierConnectionRequestRequest } from '../models/PatchedSupplierConnectionRequestRequest';
import type { PatchedSupplierEditContactRequest } from '../models/PatchedSupplierEditContactRequest';
import type { PatchedSupplierEditLocationRequest } from '../models/PatchedSupplierEditLocationRequest';
import type { PatchedSupplierEditRequest } from '../models/PatchedSupplierEditRequest';
import type { PatchedSupplierProfileRequest } from '../models/PatchedSupplierProfileRequest';
import type { PatchedUserRequest } from '../models/PatchedUserRequest';
import type { PatchedUserStateRequest } from '../models/PatchedUserStateRequest';
import type { Piggyback } from '../models/Piggyback';
import type { PiggybackRequestRequest } from '../models/PiggybackRequestRequest';
import type { ProductServiceSearchResponse } from '../models/ProductServiceSearchResponse';
import type { Project } from '../models/Project';
import type { ProjectItem } from '../models/ProjectItem';
import type { ProjectItemNoteRequest } from '../models/ProjectItemNoteRequest';
import type { ProjectItemNotesResponse } from '../models/ProjectItemNotesResponse';
import type { ProjectItemRequest } from '../models/ProjectItemRequest';
import type { ProjectNameSuggestionRequestRequest } from '../models/ProjectNameSuggestionRequestRequest';
import type { ProjectNameSuggestionResponse } from '../models/ProjectNameSuggestionResponse';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { ProQualifications } from '../models/ProQualifications';
import type { QualifiedUserInteraction } from '../models/QualifiedUserInteraction';
import type { QualifiedUserInteractionRequest } from '../models/QualifiedUserInteractionRequest';
import type { QuoteRequest } from '../models/QuoteRequest';
import type { QuoteRequestRequest } from '../models/QuoteRequestRequest';
import type { RecentlyViewedContract } from '../models/RecentlyViewedContract';
import type { RecommendSupplierToSupplierRequest } from '../models/RecommendSupplierToSupplierRequest';
import type { RoleManagement } from '../models/RoleManagement';
import type { SearchAutocomplete } from '../models/SearchAutocomplete';
import type { SearchFeedbackRequest } from '../models/SearchFeedbackRequest';
import type { SearchQualityResponse } from '../models/SearchQualityResponse';
import type { SemanticKeywordAndOffering } from '../models/SemanticKeywordAndOffering';
import type { SemanticOffering } from '../models/SemanticOffering';
import type { SemanticSupplierRecommendationResponse } from '../models/SemanticSupplierRecommendationResponse';
import type { StateFromZipResponse } from '../models/StateFromZipResponse';
import type { SupplierBenchmarkQueries } from '../models/SupplierBenchmarkQueries';
import type { SupplierBenchmarkQueriesRequest } from '../models/SupplierBenchmarkQueriesRequest';
import type { SupplierBenchmarkQuery } from '../models/SupplierBenchmarkQuery';
import type { SupplierBenchmarkRequestRequest } from '../models/SupplierBenchmarkRequestRequest';
import type { SupplierBenchmarkResponse } from '../models/SupplierBenchmarkResponse';
import type { SupplierBenchmarkStates } from '../models/SupplierBenchmarkStates';
import type { SupplierBenchmarkStatesRequest } from '../models/SupplierBenchmarkStatesRequest';
import type { SupplierBuyerLeadAgency } from '../models/SupplierBuyerLeadAgency';
import type { SupplierBuyerLeadAgencyRelationshipRequest } from '../models/SupplierBuyerLeadAgencyRelationshipRequest';
import type { SupplierConnectionRequest } from '../models/SupplierConnectionRequest';
import type { SupplierEdit } from '../models/SupplierEdit';
import type { SupplierEditAliasesRequest } from '../models/SupplierEditAliasesRequest';
import type { SupplierEditAliasesResponse } from '../models/SupplierEditAliasesResponse';
import type { SupplierEditContact } from '../models/SupplierEditContact';
import type { SupplierEditContactRequest } from '../models/SupplierEditContactRequest';
import type { SupplierEditLocation } from '../models/SupplierEditLocation';
import type { SupplierEditLocationRequest } from '../models/SupplierEditLocationRequest';
import type { SupplierEditRequest } from '../models/SupplierEditRequest';
import type { SupplierEditRequestRequest } from '../models/SupplierEditRequestRequest';
import type { SupplierEditRequestSummary } from '../models/SupplierEditRequestSummary';
import type { SupplierExclusion } from '../models/SupplierExclusion';
import type { SupplierProfile } from '../models/SupplierProfile';
import type { SupplierProfileRequest } from '../models/SupplierProfileRequest';
import type { SupplierSearchRequest } from '../models/SupplierSearchRequest';
import type { SupplierSearchResponse } from '../models/SupplierSearchResponse';
import type { SupplierUpgradeInterestRequest } from '../models/SupplierUpgradeInterestRequest';
import type { UploadedLogo } from '../models/UploadedLogo';
import type { User } from '../models/User';
import type { UserInteraction } from '../models/UserInteraction';
import type { UserInteractionRequest } from '../models/UserInteractionRequest';
import type { UserRequest } from '../models/UserRequest';
import type { UserState } from '../models/UserState';
import type { UserStateRequest } from '../models/UserStateRequest';
import type { VagueQuerySuggestionsResponse } from '../models/VagueQuerySuggestionsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * @returns AdminBuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1AdminBuyerLeadAgenciesCooperativesList(): CancelablePromise<Array<AdminBuyerLeadAgency>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/buyer-lead-agencies/cooperatives',
        });
    }
    /**
     * @param requestBody
     * @returns AdminContractFragment
     * @throws ApiError
     */
    public static apiV1AdminContractFragmentsCreate(
        requestBody?: AdminContractFragmentRequest,
    ): CancelablePromise<AdminContractFragment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/contract-fragments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param docid A unique value identifying this contract.
     * @returns AdminContract
     * @throws ApiError
     */
    public static apiV1AdminContractsRetrieve(
        docid: string,
    ): CancelablePromise<AdminContract> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/contracts/{docid}',
            path: {
                'docid': docid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AdminAddFile
     * @throws ApiError
     */
    public static apiV1AdminFileManagementsAddCreate(
        requestBody: AdminAddFileRequest,
    ): CancelablePromise<AdminAddFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/file-managements/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminRemoveFile
     * @throws ApiError
     */
    public static apiV1AdminFileManagementsRemoveCreate(
        requestBody: AdminRemoveFileRequest,
    ): CancelablePromise<AdminRemoveFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/file-managements/remove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminUpdateFile
     * @throws ApiError
     */
    public static apiV1AdminFileManagementsUpdateCreate(
        requestBody: AdminUpdateFileRequest,
    ): CancelablePromise<AdminUpdateFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/file-managements/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminOfferingMerge
     * @throws ApiError
     */
    public static apiV1AdminOfferingMergeCreate(
        requestBody: AdminOfferingMergeRequest,
    ): CancelablePromise<AdminOfferingMerge> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/offering-merge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminOfferingSynonym
     * @throws ApiError
     */
    public static apiV1AdminOfferingSynonymsCreate(
        requestBody: AdminOfferingSynonymRequest,
    ): CancelablePromise<AdminOfferingSynonym> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/offering-synonyms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminOfferingSynonym
     * @throws ApiError
     */
    public static apiV1AdminOfferingSynonymsPartialUpdate(
        id: number,
        requestBody?: PatchedAdminOfferingSynonymRequest,
    ): CancelablePromise<AdminOfferingSynonym> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/admin/offering-synonyms/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static apiV1AdminOfferingSynonymsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/admin/offering-synonyms/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param query
     * @returns OfferingSearchResponse
     * @throws ApiError
     */
    public static apiV1AdminOfferingsRetrieve(
        query: string,
    ): CancelablePromise<OfferingSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/offerings',
            query: {
                'query': query,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AdminOffering
     * @throws ApiError
     */
    public static apiV1AdminOfferingsCreate(
        requestBody: AdminOfferingRequest,
    ): CancelablePromise<AdminOffering> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/offerings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns AdminOffering
     * @throws ApiError
     */
    public static apiV1AdminOfferingsRetrieve2(
        id: number,
    ): CancelablePromise<AdminOffering> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/offerings/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminOffering
     * @throws ApiError
     */
    public static apiV1AdminOfferingsUpdate(
        id: number,
        requestBody: AdminOfferingRequest,
    ): CancelablePromise<AdminOffering> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/offerings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static apiV1AdminOfferingsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/admin/offerings/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns AdminSearchQueryResolution
     * @throws ApiError
     */
    public static apiV1AdminSearchQueryResolutionsList(): CancelablePromise<Array<AdminSearchQueryResolution>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/search-query-resolutions',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSearchQueryResolution
     * @throws ApiError
     */
    public static apiV1AdminSearchQueryResolutionsPartialUpdate(
        id: number,
        requestBody?: PatchedAdminSearchQueryResolutionRequest,
    ): CancelablePromise<AdminSearchQueryResolution> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/admin/search-query-resolutions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSearchQueryResolution
     * @throws ApiError
     */
    public static apiV1AdminSearchQueryResolutionsReresolveUpdate(
        id: number,
        requestBody?: AdminSearchQueryResolutionRequest,
    ): CancelablePromise<AdminSearchQueryResolution> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/search-query-resolutions/{id}/reresolve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns PaginatedAdminSupplierConnectionRequestList
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsList(
        limit?: number,
        offset?: number,
    ): CancelablePromise<PaginatedAdminSupplierConnectionRequestList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/supplier-connection-requests',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * @param id
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsRetrieve(
        id: number,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/supplier-connection-requests/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequestRequest,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connection-requests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsCloseUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequestRequest,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connection-requests/{id}/close',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsMergeUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequestRequest,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connection-requests/{id}/merge',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsSendStagedUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequestRequest,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connection-requests/{id}/send-staged',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsTriggerExpansionCreate(
        id: number,
        requestBody?: AdminSupplierConnectionRequestRequest,
    ): CancelablePromise<AdminSupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/supplier-connection-requests/{id}/trigger-expansion',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionRequestsEnumsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/supplier-connection-requests/enums',
        });
    }
    /**
     * @param requestBody
     * @returns AdminSupplierConnection
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionsCreate(
        requestBody?: AdminSupplierConnectionRequest,
    ): CancelablePromise<AdminSupplierConnection> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/supplier-connections',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnection
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionsUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequest,
    ): CancelablePromise<AdminSupplierConnection> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connections/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnection
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionsCloseUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequest,
    ): CancelablePromise<AdminSupplierConnection> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connections/{id}/close',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnection
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionsContactsCreate(
        id: number,
        requestBody?: AdminSupplierConnectionRequest,
    ): CancelablePromise<AdminSupplierConnection> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/supplier-connections/{id}/contacts',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AdminSupplierConnection
     * @throws ApiError
     */
    public static apiV1AdminSupplierConnectionsSnoozeUpdate(
        id: number,
        requestBody?: AdminSupplierConnectionRequest,
    ): CancelablePromise<AdminSupplierConnection> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin/supplier-connections/{id}/snooze',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminSupplierContactFragment
     * @throws ApiError
     */
    public static apiV1AdminSupplierContactFragmentsCreate(
        requestBody?: AdminSupplierContactFragmentRequest,
    ): CancelablePromise<AdminSupplierContactFragment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/supplier-contact-fragments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AdminSupplierFragment
     * @throws ApiError
     */
    public static apiV1AdminSupplierFragmentsCreate(
        requestBody?: AdminSupplierFragmentRequest,
    ): CancelablePromise<AdminSupplierFragment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/supplier-fragments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns AdminFullSupplier
     * @throws ApiError
     */
    public static apiV1AdminSuppliersRetrieve(
        id: string,
    ): CancelablePromise<AdminFullSupplier> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/suppliers/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1AdminSuppliersRefreshCreate(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/suppliers/{id}/refresh',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param supplierId
     * @returns AdminSupplierContact
     * @throws ApiError
     */
    public static apiV1AdminSuppliersContactsList(
        supplierId: string,
    ): CancelablePromise<Array<AdminSupplierContact>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/suppliers/{supplierId}/contacts',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @returns ApprovedSource
     * @throws ApiError
     */
    public static apiV1ApprovedSourcesList(): CancelablePromise<Array<ApprovedSource>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/approved-sources/',
        });
    }
    /**
     * @param requestBody
     * @returns ApprovedSource
     * @throws ApiError
     */
    public static apiV1ApprovedSourcesCreate(
        requestBody: CreateApprovedSourceRequest,
    ): CancelablePromise<ApprovedSource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/approved-sources/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this approved sources.
     * @param requestBody
     * @returns ApprovedSource
     * @throws ApiError
     */
    public static apiV1ApprovedSourcesUpdate(
        id: string,
        requestBody?: ApprovedSourceRequest,
    ): CancelablePromise<ApprovedSource> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/approved-sources/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this approved sources.
     * @param requestBody
     * @returns ApprovedSource
     * @throws ApiError
     */
    public static apiV1ApprovedSourcesPartialUpdate(
        id: string,
        requestBody?: PatchedApprovedSourceRequest,
    ): CancelablePromise<ApprovedSource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/approved-sources/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this approved sources.
     * @returns void
     * @throws ApiError
     */
    public static apiV1ApprovedSourcesDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/approved-sources/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param docid
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1AugmentContractUpdate(
        docid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/augment-contract/{docid}',
            path: {
                'docid': docid,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1BenchmarkingCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/benchmarking',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1BulkContractPriorityCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk-contract-priority',
        });
    }
    /**
     * @param query
     * @param excludeCooperatives
     * @param parentsOnly
     * @returns BuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesList(
        query: string,
        excludeCooperatives: boolean = true,
        parentsOnly: boolean = false,
    ): CancelablePromise<Array<BuyerLeadAgency>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/buyer-lead-agencies/',
            query: {
                'excludeCooperatives': excludeCooperatives,
                'parentsOnly': parentsOnly,
                'query': query,
            },
        });
    }
    /**
     * @param requestBody
     * @returns BuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesCreate(
        requestBody: CreateBLARequest,
    ): CancelablePromise<BuyerLeadAgency> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/buyer-lead-agencies/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param buyerLeadAgencyPk
     * @returns BuyerLeadAgencyInvites
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesInvitesList(
        buyerLeadAgencyPk: string,
    ): CancelablePromise<Array<BuyerLeadAgencyInvites>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/buyer-lead-agencies/{buyerLeadAgencyPk}/invites/',
            path: {
                'buyerLeadAgencyPk': buyerLeadAgencyPk,
            },
        });
    }
    /**
     * @param buyerLeadAgencyPk
     * @returns BuyerLeadAgencyMember
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesTeamList(
        buyerLeadAgencyPk: string,
    ): CancelablePromise<Array<BuyerLeadAgencyMember>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/buyer-lead-agencies/{buyerLeadAgencyPk}/team/',
            path: {
                'buyerLeadAgencyPk': buyerLeadAgencyPk,
            },
        });
    }
    /**
     * @param id A UUID string identifying this buyer lead agency.
     * @returns BuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesRetrieve(
        id: string,
    ): CancelablePromise<BuyerLeadAgency> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/buyer-lead-agencies/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A UUID string identifying this buyer lead agency.
     * @param requestBody
     * @returns BuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesUpdate(
        id: string,
        requestBody: BuyerLeadAgencyRequest,
    ): CancelablePromise<BuyerLeadAgency> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/buyer-lead-agencies/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this buyer lead agency.
     * @param requestBody
     * @returns BuyerLeadAgencySetting
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesPartialUpdate(
        id: string,
        requestBody?: PatchedBuyerLeadAgencySettingRequest,
    ): CancelablePromise<BuyerLeadAgencySetting> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/buyer-lead-agencies/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this buyer lead agency.
     * @returns void
     * @throws ApiError
     */
    public static apiV1BuyerLeadAgenciesDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/buyer-lead-agencies/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateBuyerQualificationSession
     * @throws ApiError
     */
    public static apiV1BuyerQualificationSessionsCreate(
        requestBody: CreateBuyerQualificationSessionRequest,
    ): CancelablePromise<CreateBuyerQualificationSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/buyer-qualification-sessions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param surveyId
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1BuyerSurveyPartialUpdate(
        surveyId: string,
        requestBody?: PatchedBuyerSurveyPatchResponseRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/buyer-survey/{surveyId}/',
            path: {
                'surveyId': surveyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactBugReportCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/bug-report',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactContractFeedbackCreate(
        requestBody: ContactContractFeedbackRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/contract-feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactEmailBlaCreate(
        requestBody: ContactBuyerLeadAgencyRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/email-bla',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactEmailInviteTeamCreate(
        requestBody: InviteTeamRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/email-invite-team',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1ContactEmailSupplierCreate(
        requestBody: ContactEmailSupplierRequest,
    ): CancelablePromise<SupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/email-supplier',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactMissingDocumentsCreate(
        requestBody: ContactMissingDocumentsRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/missing-documents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactSearchFeedbackCreate(
        requestBody: SearchFeedbackRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/search-feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ContactSupplierUpgradeInterestCreate(
        requestBody: SupplierUpgradeInterestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact/supplier-upgrade-interest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Performs contract search.
     * @param requestBody
     * @returns ContractSearchResponse
     * @throws ApiError
     */
    public static apiV1ContractSearchCreate(
        requestBody: ContractSearchRequest,
    ): CancelablePromise<ContractSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contract-search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ContractValidation
     * @throws ApiError
     */
    public static apiV1ContractValidationCreate(
        requestBody: ContractValidationRequest,
    ): CancelablePromise<ContractValidation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contract-validation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param validationGroupId
     * @returns ContractValidation
     * @throws ApiError
     */
    public static apiV1ContractValidationGroupRetrieve(
        validationGroupId: string,
    ): CancelablePromise<ContractValidation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contract-validation-group/{validationGroupId}',
            path: {
                'validationGroupId': validationGroupId,
            },
        });
    }
    /**
     * @param docid
     * @param requestBody
     * @returns any Contract successfully created or updated.
     * @throws ApiError
     */
    public static apiV1ContractsUpdate(
        docid: string,
        requestBody: ExternalAPIIngestedContractRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contracts/{docid}',
            path: {
                'docid': docid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. Check response body for error messages.`,
                401: `Authorization does not provide access to this endpoint.`,
                403: `No valid authorization found on request.`,
                429: `Too many requests. See response body for more information.`,
            },
        });
    }
    /**
     * @param docid
     * @returns any Contract successfully created or updated.
     * @throws ApiError
     */
    public static apiV1ContractsDestroy(
        docid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contracts/{docid}',
            path: {
                'docid': docid,
            },
            errors: {
                400: `Bad request. Check response body for error messages.`,
                401: `Authorization does not provide access to this endpoint.`,
                403: `No valid authorization found on request.`,
                429: `Too many requests. See response body for more information.`,
            },
        });
    }
    /**
     * @param docid
     * @param query
     * @returns SemanticOffering
     * @throws ApiError
     */
    public static apiV1ContractsSemanticOfferingsList(
        docid: string,
        query: string,
    ): CancelablePromise<Array<SemanticOffering>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/{docid}/semantic-offerings',
            path: {
                'docid': docid,
            },
            query: {
                'query': query,
            },
        });
    }
    /**
     * @param docid
     * @param query
     * @returns SemanticKeywordAndOffering
     * @throws ApiError
     */
    public static apiV1ContractsSemanticScopeRetrieve(
        docid: string,
        query: string,
    ): CancelablePromise<SemanticKeywordAndOffering> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/{docid}/semantic-scope',
            path: {
                'docid': docid,
            },
            query: {
                'query': query,
            },
        });
    }
    /**
     * @returns DuplicateSupplierCandidate
     * @throws ApiError
     */
    public static apiV1DuplicateSupplierCandidateList(): CancelablePromise<Array<DuplicateSupplierCandidate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/duplicate-supplier-candidate/',
        });
    }
    /**
     * Fetches all entity contracts. Can be used with a search query or filters.
     * @param requestBody
     * @returns ContractSearchResponse
     * @throws ApiError
     */
    public static apiV1EntityContractsCreate(
        requestBody: EntityContractsRequest,
    ): CancelablePromise<ContractSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/entity-contracts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns BuyerIntentSurvey
     * @throws ApiError
     */
    public static apiV1IntentSurveysList(): CancelablePromise<Array<BuyerIntentSurvey>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/intent-surveys/',
        });
    }
    /**
     * @param requestBody
     * @returns BuyerIntentSurvey
     * @throws ApiError
     */
    public static apiV1IntentSurveysCreate(
        requestBody?: BuyerIntentSurveyRequest,
    ): CancelablePromise<BuyerIntentSurvey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/intent-surveys/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this buyer intent survey.
     * @param requestBody
     * @returns BuyerIntentSurvey
     * @throws ApiError
     */
    public static apiV1IntentSurveysUpdate(
        id: string,
        requestBody?: BuyerIntentSurveyRequest,
    ): CancelablePromise<BuyerIntentSurvey> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/intent-surveys/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A UUID string identifying this buyer intent survey.
     * @param requestBody
     * @returns BuyerIntentSurvey
     * @throws ApiError
     */
    public static apiV1IntentSurveysPartialUpdate(
        id: string,
        requestBody?: PatchedBuyerIntentSurveyRequest,
    ): CancelablePromise<BuyerIntentSurvey> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/intent-surveys/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Performs landing page search.
     * @param requestBody
     * @returns ContractSearchResponse
     * @throws ApiError
     */
    public static apiV1LandingPageSearchCreate(
        requestBody: LandingPageSearchRequest,
    ): CancelablePromise<ContractSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/landing-page-search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MegaCategories
     * @throws ApiError
     */
    public static apiV1MegaCategoriesList(): CancelablePromise<Array<MegaCategories>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/mega-categories/',
        });
    }
    /**
     * @param query
     * @param typeahead
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1NigpCategoriesRetrieve(
        query: string | null,
        typeahead: boolean | null = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/nigp-categories/',
            query: {
                'query': query,
                'typeahead': typeahead,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1NigpQuerySuggestionsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/nigp-query-suggestions/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1OcrFileSearchCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ocr-file-search',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1PavilionTodayRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pavilion-today',
        });
    }
    /**
     * @returns Piggyback
     * @throws ApiError
     */
    public static apiV1PiggybackList(): CancelablePromise<Array<Piggyback>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/piggyback/',
        });
    }
    /**
     * @param requestBody
     * @returns Piggyback
     * @throws ApiError
     */
    public static apiV1PiggybackCreate(
        requestBody: PiggybackRequestRequest,
    ): CancelablePromise<Array<Piggyback>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/piggyback/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this piggyback.
     * @returns void
     * @throws ApiError
     */
    public static apiV1PiggybackDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/piggyback/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Returns data completeness information for all pro-suppliers.
     * @param filter * `ONLY_PRIORITY` - Only Priority
     * * `ONLY_ACTIVE` - Only Active
     * * `ALL` - All
     * @param includeExpired
     * @param page
     * @param pageSize
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1ProSupplierEvaluationsRetrieve(
        filter: 'ONLY_PRIORITY' | 'ONLY_ACTIVE' | 'ALL' | null = 'ALL',
        includeExpired: boolean | null = false,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pro-supplier-evaluations',
            query: {
                'filter': filter,
                'includeExpired': includeExpired,
                'page': page,
                'pageSize': pageSize,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param page
     * @param query
     * @returns ProductServiceSearchResponse
     * @throws ApiError
     */
    public static apiV1ProductServicesRetrieve(
        supplierHandle: string,
        page?: number,
        query?: string,
    ): CancelablePromise<ProductServiceSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product-services/',
            query: {
                'page': page,
                'query': query,
                'supplier_handle': supplierHandle,
            },
        });
    }
    /**
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsList(): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/',
        });
    }
    /**
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsCreate(
        requestBody: ProjectRequest,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique value identifying this project.
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsRetrieve(
        id: string,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsUpdate(
        id: string,
        requestBody: ProjectRequest,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsPartialUpdate(
        id: string,
        requestBody?: PatchedProjectRequest,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsArchiveCreate(
        id: string,
        requestBody?: ArchiveProjectRequest,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{id}/archive/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiV1ProjectsInviteCreate(
        id: string,
        requestBody: InviteToProjectRequest,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{id}/invite/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param projectPk
     * @returns ProjectItem
     * @throws ApiError
     */
    public static apiV1ProjectsItemsList(
        projectPk: string,
    ): CancelablePromise<Array<ProjectItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{projectPk}/items/',
            path: {
                'projectPk': projectPk,
            },
        });
    }
    /**
     * @param projectPk
     * @param requestBody
     * @returns ProjectItem
     * @throws ApiError
     */
    public static apiV1ProjectsItemsCreate(
        projectPk: string,
        requestBody: ProjectItemRequest,
    ): CancelablePromise<ProjectItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{projectPk}/items/',
            path: {
                'projectPk': projectPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param itemPk
     * @param projectPk
     * @param requestBody
     * @returns ProjectItemNotesResponse
     * @throws ApiError
     */
    public static apiV1ProjectsItemsNotesCreate(
        itemPk: string,
        projectPk: string,
        requestBody: ProjectItemNoteRequest,
    ): CancelablePromise<ProjectItemNotesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{projectPk}/items/{itemPk}/notes/',
            path: {
                'itemPk': itemPk,
                'projectPk': projectPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id item_id (either docid or handle) that uniquely identifies the project item along with the project_pk
     * @param projectPk
     * @returns void
     * @throws ApiError
     */
    public static apiV1ProjectsItemsDestroy(
        id: string,
        projectPk: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/projects/{projectPk}/items/{id}/',
            path: {
                'id': id,
                'projectPk': projectPk,
            },
        });
    }
    /**
     * Given the name of a Contract and/or Supplier, provides a possible name
     * for a Project that the user might want to create.
     * @param requestBody
     * @returns ProjectNameSuggestionResponse
     * @throws ApiError
     */
    public static apiV1ProjectsNameSuggestionCreate(
        requestBody?: ProjectNameSuggestionRequestRequest,
    ): CancelablePromise<ProjectNameSuggestionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/name-suggestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1QualifiableUserInteractionsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/qualifiable-user-interactions/',
        });
    }
    /**
     * @param requestBody
     * @returns QualifiedUserInteraction
     * @throws ApiError
     */
    public static apiV1QualifiedUserInteractionsCreate(
        requestBody: QualifiedUserInteractionRequest,
    ): CancelablePromise<QualifiedUserInteraction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/qualified-user-interactions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this qualified user interaction.
     * @param requestBody
     * @returns QualifiedUserInteraction
     * @throws ApiError
     */
    public static apiV1QualifiedUserInteractionsUpdate(
        id: number,
        requestBody: QualifiedUserInteractionRequest,
    ): CancelablePromise<QualifiedUserInteraction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/qualified-user-interactions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this qualified user interaction.
     * @param requestBody
     * @returns QualifiedUserInteraction
     * @throws ApiError
     */
    public static apiV1QualifiedUserInteractionsPartialUpdate(
        id: number,
        requestBody?: PatchedQualifiedUserInteractionRequest,
    ): CancelablePromise<QualifiedUserInteraction> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/qualified-user-interactions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns QuoteRequest
     * @throws ApiError
     */
    public static apiV1QuoteRequestsCreate(
        requestBody: QuoteRequestRequest,
    ): CancelablePromise<QuoteRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/quote-requests/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns RecentlyViewedContract
     * @throws ApiError
     */
    public static apiV1RecentlyViewedContractsList(): CancelablePromise<Array<RecentlyViewedContract>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recently-viewed-contracts',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1RecommendationsSemanticContractCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recommendations/semantic-contract',
        });
    }
    /**
     * Generates supplier -> supplier recommendations
     * First, get contracts attached to a supplier
     * Then, get recommendations for each contract in parallel
     * Then, get suppliers attached to the recommended contracts to recommend
     * @param requestBody
     * @returns SemanticSupplierRecommendationResponse
     * @throws ApiError
     */
    public static apiV1RecommendationsSupplierCreate(
        requestBody: RecommendSupplierToSupplierRequest,
    ): CancelablePromise<SemanticSupplierRecommendationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recommendations/supplier',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns RoleManagement
     * @throws ApiError
     */
    public static apiV1RoleManagementCreate(): CancelablePromise<RoleManagement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/role-management/',
        });
    }
    /**
     * @param requestBody
     * @returns RoleManagement
     * @throws ApiError
     */
    public static apiV1RoleManagementChangeRoleCreate(
        requestBody: ChangeRoleRequest,
    ): CancelablePromise<RoleManagement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/role-management/change-role/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param query
     * @returns SearchAutocomplete
     * @throws ApiError
     */
    public static apiV1SearchAutocompleteRetrieve(
        query: string,
    ): CancelablePromise<SearchAutocomplete> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/search-autocomplete/',
            query: {
                'query': query,
            },
        });
    }
    /**
     * Retrieve search MRR and NDCG metrics.
     * @param buyerProfileTypes
     * @param endDate
     * @param excludeSupplierMatches
     * @param k
     * @param partitionBy
     * @param period
     * @param startDate
     * @returns SearchQualityResponse
     * @throws ApiError
     */
    public static apiV1SearchQualityRetrieve(
        buyerProfileTypes?: string,
        endDate?: string,
        excludeSupplierMatches: boolean = true,
        k: number = 10,
        partitionBy?: string,
        period: string = '1W',
        startDate?: string,
    ): CancelablePromise<SearchQualityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/search-quality/',
            query: {
                'buyerProfileTypes': buyerProfileTypes,
                'endDate': endDate,
                'excludeSupplierMatches': excludeSupplierMatches,
                'k': k,
                'partitionBy': partitionBy,
                'period': period,
                'startDate': startDate,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SharedLinksCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shared-links/',
        });
    }
    /**
     * @param contractId
     * @param solicitationId
     * @param ignoredContracts
     * @param page
     * @param query
     * @param size
     * @param sortByPro
     * @returns OtherContractsResponse
     * @throws ApiError
     */
    public static apiV1SolicitationsOtherRetrieve(
        contractId: string,
        solicitationId: string,
        ignoredContracts?: string,
        page?: number,
        query?: string | null,
        size: number = 3,
        sortByPro: boolean = false,
    ): CancelablePromise<OtherContractsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/solicitations/{solicitationId}/{contractId}/other',
            path: {
                'contractId': contractId,
                'solicitationId': solicitationId,
            },
            query: {
                'ignored_contracts': ignoredContracts,
                'page': page,
                'query': query,
                'size': size,
                'sort_by_pro': sortByPro,
            },
        });
    }
    /**
     * @param contractId
     * @param solicitationId
     * @param page
     * @param size
     * @returns OtherContractsResponse
     * @throws ApiError
     */
    public static apiV1SolicitationsResellerRetrieve(
        contractId: string,
        solicitationId: string,
        page?: number,
        size: number = 3,
    ): CancelablePromise<OtherContractsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/solicitations/{solicitationId}/{contractId}/reseller',
            path: {
                'contractId': contractId,
                'solicitationId': solicitationId,
            },
            query: {
                'page': page,
                'size': size,
            },
        });
    }
    /**
     * @param zip
     * @returns StateFromZipResponse
     * @throws ApiError
     */
    public static apiV1StateFromZipRetrieve(
        zip: string,
    ): CancelablePromise<StateFromZipResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/state-from-zip',
            query: {
                'zip': zip,
            },
            errors: {
                400: `Bad request. Check response body for error messages.`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns SupplierConnectionRequest
     * @throws ApiError
     */
    public static apiV1SupplierConnectionRequestsPartialUpdate(
        id: number,
        requestBody?: PatchedSupplierConnectionRequestRequest,
    ): CancelablePromise<SupplierConnectionRequest> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/supplier-connection-requests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierHandle
     * @returns void
     * @throws ApiError
     */
    public static apiV1SupplierEditLogoLogoDeleteDestroy(
        supplierHandle: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/supplier-edit-logo/{supplierHandle}/logo-delete/',
            path: {
                'supplierHandle': supplierHandle,
            },
            errors: {
                400: `Bad request. Check response body for error messages.`,
                403: `Bad request. Deleting a logo is forbidden.`,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param formData
     * @returns UploadedLogo
     * @throws ApiError
     */
    public static apiV1SupplierEditLogoLogoUploadCreate(
        supplierHandle: string,
        formData?: {
            image?: Blob;
        },
    ): CancelablePromise<UploadedLogo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit-logo/{supplierHandle}/logo-upload/',
            path: {
                'supplierHandle': supplierHandle,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad request. Check response body for error messages.`,
                403: `Bad request. Uploading a logo is forbidden.`,
                500: `Internal server error. Check response body for error messages.`,
            },
        });
    }
    /**
     * @param handle
     * @param requestBody
     * @returns SupplierEdit
     * @throws ApiError
     */
    public static apiV1SupplierEditUpdate(
        handle: string,
        requestBody: SupplierEditRequest,
    ): CancelablePromise<SupplierEdit> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/supplier-edit/{handle}/',
            path: {
                'handle': handle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param handle
     * @param requestBody
     * @returns SupplierEdit
     * @throws ApiError
     */
    public static apiV1SupplierEditPartialUpdate(
        handle: string,
        requestBody?: PatchedSupplierEditRequest,
    ): CancelablePromise<SupplierEdit> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/supplier-edit/{handle}/',
            path: {
                'handle': handle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditAliasesResponse
     * @throws ApiError
     */
    public static apiV1SupplierEditAliasesCreate(
        supplierHandle: string,
        requestBody?: SupplierEditAliasesRequest,
    ): CancelablePromise<SupplierEditAliasesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit/{supplierHandle}/aliases',
            path: {
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierHandle
     * @returns SupplierBuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1SupplierEditBuyerLeadAgenciesList(
        supplierHandle: string,
    ): CancelablePromise<Array<SupplierBuyerLeadAgency>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/supplier-edit/{supplierHandle}/buyer-lead-agencies/',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierBuyerLeadAgency
     * @throws ApiError
     */
    public static apiV1SupplierEditBuyerLeadAgenciesCreate(
        supplierHandle: string,
        requestBody: SupplierBuyerLeadAgencyRelationshipRequest,
    ): CancelablePromise<SupplierBuyerLeadAgency> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit/{supplierHandle}/buyer-lead-agencies/',
            path: {
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier buyer lead agency relationship.
     * @param supplierHandle
     * @returns void
     * @throws ApiError
     */
    public static apiV1SupplierEditBuyerLeadAgenciesDestroy(
        id: number,
        supplierHandle: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/supplier-edit/{supplierHandle}/buyer-lead-agencies/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditContact
     * @throws ApiError
     */
    public static apiV1SupplierEditContactsCreate(
        supplierHandle: string,
        requestBody: SupplierEditContactRequest,
    ): CancelablePromise<SupplierEditContact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit/{supplierHandle}/contacts/',
            path: {
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier contact.
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditContact
     * @throws ApiError
     */
    public static apiV1SupplierEditContactsUpdate(
        id: number,
        supplierHandle: string,
        requestBody: SupplierEditContactRequest,
    ): CancelablePromise<SupplierEditContact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/supplier-edit/{supplierHandle}/contacts/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier contact.
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditContact
     * @throws ApiError
     */
    public static apiV1SupplierEditContactsPartialUpdate(
        id: number,
        supplierHandle: string,
        requestBody?: PatchedSupplierEditContactRequest,
    ): CancelablePromise<SupplierEditContact> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/supplier-edit/{supplierHandle}/contacts/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier contact.
     * @param supplierHandle
     * @returns void
     * @throws ApiError
     */
    public static apiV1SupplierEditContactsDestroy(
        id: number,
        supplierHandle: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/supplier-edit/{supplierHandle}/contacts/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @returns SupplierExclusion
     * @throws ApiError
     */
    public static apiV1SupplierEditExclusionsList(
        supplierHandle: string,
    ): CancelablePromise<Array<SupplierExclusion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/supplier-edit/{supplierHandle}/exclusions/',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @returns SupplierExclusion
     * @throws ApiError
     */
    public static apiV1SupplierEditExclusionsCreate(
        supplierHandle: string,
    ): CancelablePromise<SupplierExclusion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit/{supplierHandle}/exclusions/',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this supplier exclusion.
     * @param supplierHandle
     * @returns void
     * @throws ApiError
     */
    public static apiV1SupplierEditExclusionsDestroy(
        id: number,
        supplierHandle: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/supplier-edit/{supplierHandle}/exclusions/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @returns SupplierEditLocation
     * @throws ApiError
     */
    public static apiV1SupplierEditLocationsList(
        supplierHandle: string,
    ): CancelablePromise<Array<SupplierEditLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/supplier-edit/{supplierHandle}/locations/',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditLocation
     * @throws ApiError
     */
    public static apiV1SupplierEditLocationsCreate(
        supplierHandle: string,
        requestBody?: SupplierEditLocationRequest,
    ): CancelablePromise<SupplierEditLocation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-edit/{supplierHandle}/locations/',
            path: {
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier location.
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditLocation
     * @throws ApiError
     */
    public static apiV1SupplierEditLocationsUpdate(
        id: number,
        supplierHandle: string,
        requestBody?: SupplierEditLocationRequest,
    ): CancelablePromise<SupplierEditLocation> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/supplier-edit/{supplierHandle}/locations/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier location.
     * @param supplierHandle
     * @param requestBody
     * @returns SupplierEditLocation
     * @throws ApiError
     */
    public static apiV1SupplierEditLocationsPartialUpdate(
        id: number,
        supplierHandle: string,
        requestBody?: PatchedSupplierEditLocationRequest,
    ): CancelablePromise<SupplierEditLocation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/supplier-edit/{supplierHandle}/locations/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this supplier location.
     * @param supplierHandle
     * @returns void
     * @throws ApiError
     */
    public static apiV1SupplierEditLocationsDestroy(
        id: number,
        supplierHandle: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/supplier-edit/{supplierHandle}/locations/{id}/',
            path: {
                'id': id,
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SupplierMergeChangeStatusCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-merge/change-status',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SupplierMergeMergeCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-merge/merge',
        });
    }
    /**
     * @param requestBody
     * @returns SupplierSearchResponse
     * @throws ApiError
     */
    public static apiV1SupplierSearchCreate(
        requestBody: SupplierSearchRequest,
    ): CancelablePromise<SupplierSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/supplier-search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/',
        });
    }
    /**
     * @param supplierHandle
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContractUploadCreate(
        supplierHandle: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierHandle}/contract-upload',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierHandle
     * @param useOriginatingSearchQueries
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersViewAnalyticsRetrieve(
        supplierHandle: string,
        useOriginatingSearchQueries: boolean | null = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierHandle}/view-analytics',
            path: {
                'supplierHandle': supplierHandle,
            },
            query: {
                'use_originating_search_queries': useOriginatingSearchQueries,
            },
        });
    }
    /**
     * @param supplierHandle
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersViewAnalyticsDownloadRetrieve(
        supplierHandle: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierHandle}/view-analytics-download',
            path: {
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param agreementId
     * @param supplierHandle
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersViewAnalyticsRetrieve2(
        agreementId: number,
        supplierHandle: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierHandle}/view-analytics/{agreementId}',
            path: {
                'agreementId': agreementId,
                'supplierHandle': supplierHandle,
            },
        });
    }
    /**
     * @param supplierId
     * @returns SupplierBenchmarkResponse
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkList(
        supplierId: number,
    ): CancelablePromise<Array<SupplierBenchmarkResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierBenchmarkResponse
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkCreate(
        supplierId: number,
        requestBody?: SupplierBenchmarkRequestRequest,
    ): CancelablePromise<SupplierBenchmarkResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns SupplierBenchmarkQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkCompetitorQueriesList(
        supplierId: number,
    ): CancelablePromise<Array<SupplierBenchmarkQueries>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark-competitor-queries',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierBenchmarkQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkCompetitorQueriesCreate(
        supplierId: number,
        requestBody: SupplierBenchmarkQueriesRequest,
    ): CancelablePromise<SupplierBenchmarkQueries> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark-competitor-queries',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns SupplierBenchmarkQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkExclusionQueriesList(
        supplierId: number,
    ): CancelablePromise<Array<SupplierBenchmarkQueries>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark-exclusion-queries',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierBenchmarkQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkExclusionQueriesCreate(
        supplierId: number,
        requestBody: SupplierBenchmarkQueriesRequest,
    ): CancelablePromise<SupplierBenchmarkQueries> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark-exclusion-queries',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns SupplierBenchmarkQuery
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkQueriesList(
        supplierId: number,
    ): CancelablePromise<Array<SupplierBenchmarkQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark-queries',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierBenchmarkQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkQueriesCreate(
        supplierId: number,
        requestBody: SupplierBenchmarkQueriesRequest,
    ): CancelablePromise<SupplierBenchmarkQueries> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark-queries',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Evaluates the supplier's performance on contract search.
     * @param supplierId
     * @param filters
     * @param proBoost
     * @param rankStrategy
     * @param zip
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkSearchRetrieve(
        supplierId: number,
        filters?: Array<any>,
        proBoost?: number | null,
        rankStrategy?: string | null,
        zip?: string | null,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark-search',
            path: {
                'supplierId': supplierId,
            },
            query: {
                'filters': filters,
                'proBoost': proBoost,
                'rankStrategy': rankStrategy,
                'zip': zip,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns BenchmarkFromQueries
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkSearchQueriesCreate(
        supplierId: number,
        requestBody: BenchmarkSearchQueriesRequest,
    ): CancelablePromise<Array<BenchmarkFromQueries>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark-search-queries',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns SupplierBenchmarkStates
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkStatesList(
        supplierId: number,
    ): CancelablePromise<Array<SupplierBenchmarkStates>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/benchmark-states',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierBenchmarkStates
     * @throws ApiError
     */
    public static apiV1SuppliersBenchmarkStatesCreate(
        supplierId: number,
        requestBody: SupplierBenchmarkStatesRequest,
    ): CancelablePromise<SupplierBenchmarkStates> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/benchmark-states',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersConfirmedExpiredContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/confirmed-expired-contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * Gets full info for a specific contact for a supplier.
     * @param contactId
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContactsRetrieve(
        contactId: number,
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/contacts/{contactId}/',
            path: {
                'contactId': contactId,
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContractLimitedNonProContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/contract-limited-non-pro-contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param docid
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContractsPartialUpdate(
        docid: string,
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/suppliers/{supplierId}/contracts/{docid}/',
            path: {
                'docid': docid,
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param docid
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersContractsRenewalConfirmationCreate(
        docid: string,
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/contracts/{docid}/renewal-confirmation/',
            path: {
                'docid': docid,
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @param requestBody
     * @returns SupplierEditRequestSummary
     * @throws ApiError
     */
    public static apiV1SuppliersEditRequestCreate(
        supplierId: number,
        requestBody: SupplierEditRequestRequest,
    ): CancelablePromise<SupplierEditRequestSummary> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/edit-request',
            path: {
                'supplierId': supplierId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param supplierId
     * @returns SupplierEditRequestSummary
     * @throws ApiError
     */
    public static apiV1SuppliersEditRequestSummaryRetrieve(
        supplierId: number,
    ): CancelablePromise<SupplierEditRequestSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/edit-request-summary',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns IntermediatedEmailAlias
     * @throws ApiError
     */
    public static apiV1SuppliersIntermediatedEmailRetrieve(
        supplierId: number,
    ): CancelablePromise<IntermediatedEmailAlias> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/intermediated-email/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersNeedsReviewContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/needs-review-contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersOptimizedContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/optimized-contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns ProQualifications
     * @throws ApiError
     */
    public static apiV1SuppliersProQualificationsRetrieve(
        supplierId: number,
    ): CancelablePromise<ProQualifications> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/pro-qualifications/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersUnsupportedContractsRetrieve(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suppliers/{supplierId}/unsupported-contracts/',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param supplierId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1SuppliersUploadProductListCreate(
        supplierId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/suppliers/{supplierId}/upload-product-list',
            path: {
                'supplierId': supplierId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserInteraction
     * @throws ApiError
     */
    public static apiV1UserInteractionCreate(
        requestBody: UserInteractionRequest,
    ): CancelablePromise<UserInteraction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-interaction',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @returns User
     * @throws ApiError
     */
    public static apiV1UsersRetrieve(
        resourceLocator: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
            },
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static apiV1UsersUpdate(
        resourceLocator: string,
        requestBody: UserRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static apiV1UsersPartialUpdate(
        resourceLocator: string,
        requestBody?: PatchedUserRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param resourceLocator
     * @returns User
     * @throws ApiError
     */
    public static apiV1UsersDiversityCertificationsRetrieve(
        resourceLocator: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{resourceLocator}/diversity-certifications/',
            path: {
                'resourceLocator': resourceLocator,
            },
        });
    }
    /**
     * @param resourceLocator
     * @returns boolean
     * @throws ApiError
     */
    public static apiV1UsersEmailVerifiedRetrieve(
        resourceLocator: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{resourceLocator}/email-verified/',
            path: {
                'resourceLocator': resourceLocator,
            },
        });
    }
    /**
     * Retrieve the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @returns BuyerProfile
     * @throws ApiError
     */
    public static apiV1UsersBuyerProfilesRetrieve(
        resourceLocator: string,
        usersResourceLocator: string,
    ): CancelablePromise<BuyerProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{usersResourceLocator}/buyer-profiles/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns BuyerProfile
     * @throws ApiError
     */
    public static apiV1UsersBuyerProfilesUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody?: BuyerProfileRequest,
    ): CancelablePromise<BuyerProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/{usersResourceLocator}/buyer-profiles/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns BuyerProfile
     * @throws ApiError
     */
    public static apiV1UsersBuyerProfilesPartialUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody?: PatchedBuyerProfileRequest,
    ): CancelablePromise<BuyerProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{usersResourceLocator}/buyer-profiles/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns SupplierProfile
     * @throws ApiError
     */
    public static apiV1UsersSupplierProfilesUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody?: SupplierProfileRequest,
    ): CancelablePromise<SupplierProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/{usersResourceLocator}/supplier-profiles/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns SupplierProfile
     * @throws ApiError
     */
    public static apiV1UsersSupplierProfilesPartialUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody?: PatchedSupplierProfileRequest,
    ): CancelablePromise<SupplierProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{usersResourceLocator}/supplier-profiles/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @returns UserState
     * @throws ApiError
     */
    public static apiV1UsersUserStatesRetrieve(
        resourceLocator: string,
        usersResourceLocator: string,
    ): CancelablePromise<UserState> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{usersResourceLocator}/user-states/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns UserState
     * @throws ApiError
     */
    public static apiV1UsersUserStatesUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody: UserStateRequest,
    ): CancelablePromise<UserState> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/{usersResourceLocator}/user-states/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the logged-in user by the "me" keyword
     * returning a 404 if not to avoid leaking information.
     * @param resourceLocator
     * @param usersResourceLocator
     * @param requestBody
     * @returns UserState
     * @throws ApiError
     */
    public static apiV1UsersUserStatesPartialUpdate(
        resourceLocator: string,
        usersResourceLocator: string,
        requestBody?: PatchedUserStateRequest,
    ): CancelablePromise<UserState> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{usersResourceLocator}/user-states/{resourceLocator}/',
            path: {
                'resourceLocator': resourceLocator,
                'usersResourceLocator': usersResourceLocator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns clusters of similar historical queries for each given query.
     * @param queries
     * @param clusterSize
     * @param distanceThreshold
     * @param getPerformanceOnCluster
     * @param startDate
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1UsersearchClustersRetrieve(
        queries: Array<string>,
        clusterSize?: number | null,
        distanceThreshold?: number | null,
        getPerformanceOnCluster: boolean | null = false,
        startDate?: string | null,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/usersearch-clusters',
            query: {
                'clusterSize': clusterSize,
                'distanceThreshold': distanceThreshold,
                'getPerformanceOnCluster': getPerformanceOnCluster,
                'queries': queries,
                'startDate': startDate,
            },
        });
    }
    /**
     * Returns whether the given query is vague, and a list of specific suggestions if so.
     * @param query
     * @returns VagueQuerySuggestionsResponse
     * @throws ApiError
     */
    public static apiV1VagueQuerySuggestionsRetrieve(
        query: string,
    ): CancelablePromise<VagueQuerySuggestionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/vague-query-suggestions/',
            query: {
                'query': query,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksMixmaxBouncedEmailResponseCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/mixmax/bounced-email-response/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksMixmaxExpansionOfferResponseCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/mixmax/expansion-offer-response/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksMixmaxInboundEmailCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/mixmax/inbound-email/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksMixmaxSequenceStageChangeCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/mixmax/sequence-stage-change/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksSendgridParseCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/sendgrid/parse',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksSupplierMessageResponseCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/supplier-message-response/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksTwilioAnswerRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/webhooks/twilio/answer',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksTwilioAnswerCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/twilio/answer',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksTwilioStatusRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/webhooks/twilio/status',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiV1WebhooksTwilioStatusCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhooks/twilio/status',
        });
    }
    /**
     * Get the zip code from the user's IP address
     * @returns string
     * @throws ApiError
     */
    public static apiV1ZipCodeFromIpRetrieve(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/zip-code-from-ip',
        });
    }
}
